.project-intro {
    font-weight: 400;
    margin-bottom: 3em;
}

.project-website {
    text-transform: uppercase;
    font-style: italic;
    font-weight: 600;
    font-family: $ff-futura;
    border-color: transparent;

    &:hover {
        border-color: currentcolor;
    }
}

.project-images {
    margin: 5em 5em 0;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.project-image {
    margin: 0;
}

.project-footer {
    clear: both;
}