.features {
    margin: 5em 0;
    padding: 0;
    list-style-type: none;
}

.feature {
    margin-bottom: 5em;
}

.feature-figure {
    margin-bottom: 1.8em;
    img {
        display: block;
    }
}

.feature-title {
    font-family: $ff-futura;
    font-size: 3rem;
    text-transform: uppercase;
    color: $c-white;
    margin: 0 0 0.4em;
    line-height: 1;
    a {
        color: inherit;
    }
}

.feature-summary {
    margin: 0;
    font-size: 2rem;
    color: $c-echo;
}
