// Home page
.no-js #tweet-template {
    display: none;
}
.tweets {
    position: relative;
    margin-bottom: 12rem;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 8px;
        height: 20px;
        background-color: white;
        transition: 0.2s;
        opacity: 0;
        animation: none;
    }

    &.is-busy {
        &:before {
            animation: blink 0.25s 0s ease infinite alternate;
            opacity: 1;
        }
    }
}
.tweet {
    $width: (($d-column*4) + ($d-gutter*3)) / $d-siteWidth * 100%;
    width: $width;
    padding: 2.4em;
    text-align: left;
    background-color: lighten($c-bg, 1%);

    transition: 0.4s;
    opacity: 1;

    .is-busy & {
        opacity: 0;
    }

}
.tweet-link {
    svg {
        transition: 0.2s ease;
        fill: $c-alpha;
    }
    &:hover {
        border-bottom: transparent;
        svg {
            fill: $c-bravo;
        }
    }
}
.tweet-header {
    margin-bottom: 1.2em;
}
.tweet-time {
    font-size: 1.6rem;
    font-style: italic;
    color: $c-echo;
    font-style: italic;
}
.tweet-body {
    color: $c-white;
}

// About page
.clients {
    list-style-type: none;
    padding: 0;
}
.client {
    margin: 1em 0;
    width: calc(100%/5);
}

// Contact page
.contact-email {
    border: none;
    color: $c-alpha;
    letter-spacing: 2px;

    &:hover {
    }
}
