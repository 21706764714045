@charset "UTF-8";
.f-futura {
  font-family: "futura-pt", "Helvetica Neue", Helvetica, Arial;
}

/**
 * A rem font-size mixin providing fallback to px
 */
/* ==========================================================================
   HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none;
}

/* ==========================================================================
   Base
   ========================================================================== */
/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */
}

/**
 * Address `font-family` inconsistency between `textarea` and other form
 * elements.
 */
html,
button,
input,
select,
textarea {
  font-family: sans-serif;
}

/**
 * Address margins handled incorrectly in IE 6/7.
 */
body {
  margin: 0;
}

/* ==========================================================================
   Links
   ========================================================================== */
/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0;
}

/* ==========================================================================
   Typography
   ========================================================================== */
/**
 * Address font sizes and margins set differently in IE 6/7.
 * Address font sizes within `section` and `article` in Firefox 4+, Safari 5,
 * and Chrome.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

h2 {
  font-size: 1.5em;
  margin: 0.83em 0;
}

h3 {
  font-size: 1.17em;
  margin: 1em 0;
}

h4 {
  font-size: 1em;
  margin: 1.33em 0;
}

h5 {
  font-size: 0.83em;
  margin: 1.67em 0;
}

h6 {
  font-size: 0.67em;
  margin: 2.33em 0;
}

/**
 * Address styling not present in IE 7/8/9, Safari 5, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted;
}

/**
 * Address style set to `bolder` in Firefox 3+, Safari 4/5, and Chrome.
 */
b,
strong {
  font-weight: bold;
}

blockquote {
  margin: 1em 40px;
}

/**
 * Address styling not present in Safari 5 and Chrome.
 */
dfn {
  font-style: italic;
}

/**
 * Address differences between Firefox and other browsers.
 * Known issue: no IE 6/7 normalization.
 */
hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}

/**
 * Address styling not present in IE 6/7/8/9.
 */
mark {
  background: #ff0;
  color: #000;
}

/**
 * Address margins set differently in IE 6/7.
 */
p,
pre {
  margin: 1em 0;
}

/**
 * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  _font-family: 'courier new', monospace;
  font-size: 1em;
}

/**
 * Improve readability of pre-formatted text in all browsers.
 */
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

/**
 * Address CSS quotes not supported in IE 6/7.
 */
q {
  quotes: none;
}

/**
 * Address `quotes` property not supported in Safari 4.
 */
q:before,
q:after {
  content: '';
  content: none;
}

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* ==========================================================================
   Lists
   ========================================================================== */
/**
 * Address margins set differently in IE 6/7.
 */
dl,
menu,
ol,
ul {
  margin: 1em 0;
}

dd {
  margin: 0 0 0 40px;
}

/**
 * Address paddings set differently in IE 6/7.
 */
menu,
ol,
ul {
  padding: 0 0 0 40px;
}

/**
 * Correct list images handled incorrectly in IE 7.
 */
nav ul,
nav ol {
  list-style: none;
  list-style-image: none;
}

/* ==========================================================================
   Embedded content
   ========================================================================== */
/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */
}

/**
 * Correct overflow displayed oddly in IE 9.
 */
svg:not(:root) {
  overflow: hidden;
}

/* ==========================================================================
   Figures
   ========================================================================== */
/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0;
}

/* ==========================================================================
   Forms
   ========================================================================== */
/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  white-space: normal;
  /* 2 */
  *margin-left: -7px;
  /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
  *height: 13px;
  /* 3 */
  *width: 13px;
  /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */
}

/* ==========================================================================
   Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}

::-moz-selection {
  background-color: #f027fc;
  color: #fff;
}

::selection {
  background-color: #f027fc;
  color: #fff;
  animation: 4s 0s test linear alternate infinite;
}

a::-moz-selection {
  background-color: #02B2FF;
  color: #fff;
}

a::selection {
  background-color: #02B2FF;
  color: #fff;
}

html, body {
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 62.5%;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

body {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial;
  background-color: #1d161f;
  color: #9D98A0;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.6;
  text-align: center;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
.js.no-font body {
  transition: 0.4s ease-out opacity;
  opacity: 0;
}
.js.wf-inactive body, .js.wf-active body {
  opacity: 1;
}
body.is-konami {
  animation: 6s linear 1 miamivice-flash 0s forwards;
}

img {
  max-width: 100%;
}

p:last-child {
  margin-bottom: 0;
}

a {
  color: #02B2FF;
  text-decoration: none;
  transition: 0.3s ease border-color, 0.3s ease color;
  border-bottom: 2px solid transparent;
}
figure a {
  border: none;
}
.status a {
  color: #fff;
  border-bottom: 2px solid currentColor;
}

a:hover {
  color: #f027fc;
  border-bottom-color: currentColor;
}
.status a:hover {
  color: #fff;
  border-bottom-color: transparent;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes miamivice {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(-80deg);
  }
}
@keyframes miamivice-logo {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(-100deg);
  }
}
@keyframes miamivice-flash {
  0%,
    5%, 7%, 9%, 11%, 13% {
    filter: hue-rotate(0deg);
  }
  6%, 8%, 10%, 12%, 14%, 100% {
    filter: hue-rotate(-100deg);
  }
}
.icon {
  width: 32px;
  height: 32px;
  margin: auto;
  fill: white;
  display: inline-block;
}

.icon--light {
  fill: #5C4B61;
}

.icon--small {
  width: 24px;
  height: 24px;
  margin-right: 0.2em;
  position: relative;
  top: 0.3em;
}

.icon--block {
  display: block;
  margin-bottom: 30px;
}

.polygon {
  width: 32px;
  align-self: center;
}
.polygon svg {
  stroke: #00ffff;
  stroke-width: 16px;
  fill: none;
}

/**
 * Clearfix
 */
.u-cf:before, .u-cf:after {
  content: ' ';
  display: table;
}

.u-cf {
  *zoom: 1;
}
.u-cf:after {
  line-height: 0;
  clear: both;
}

/**
 * Responsive Images
 */
.u-ri > img {
  max-width: 100%;
}

/**
 * Image Replacement - HTML5BP
 */
.u-ir {
  border: 0;
  font: 0/0 a;
  text-shadow: none;
  color: transparent;
}

.u-textleft {
  text-align: left;
}

.u-flex {
  display: flex;
  flex-wrap: wrap;
}

.u-flex--row {
  justify-content: space-around;
  align-items: flex-start;
}

.u-flex--spacebetween {
  justify-content: space-between;
}

.u-flex--stretch {
  align-items: stretch;
}

.u-flex--center-v {
  align-items: center;
}

.u-rv {
  position: absolute;
  height: 0%;
  width: 100%;
  padding-bottom: 56.25%;
  top: 0;
  bottom: 0;
  margin: auto;
}
.u-rv iframe,
.u-rv object,
.u-rv video {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.u-grain:before {
  content: "";
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADAgMAAAArRl0sAAAADFBMVEUAAAD///////////84wDuoAAAABHRSTlMADRoztLZuBAAAAA5JREFUCB1jOMCgwMACAAQqAOWPj3ieAAAAAElFTkSuQmCC");
  background-repeat: repeat;
  background-position: center center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

::-webkit-validation-bubble-message {
  padding: 40px;
}

.btn {
  font-family: "futura-pt", "Helvetica Neue", Helvetica, Arial;
  font-size: 1.6rem;
  font-weight: 600;
  font-style: italic;
  text-transform: uppercase;
  color: #fff;
  border: 2px solid #fff;
  display: inline-block;
  padding: 1em 2.2em;
  min-width: 220px;
  text-align: center;
  background: transparent;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: 0.2s 0.02s ease;
}
.btn:after {
  content: "";
  top: 0;
  bottom: 0;
  right: -15px;
  margin: auto;
  position: absolute;
  z-index: -1;
  transition: 0.4s 0.1s cubic-bezier(0.19, 1, 0.22, 1), 0s left, 0s right;
  background-color: #fff;
  opacity: 1;
  transform: skew(-25deg);
  width: 0%;
  height: 105%;
}
.btn:hover {
  border-color: #fff;
  color: #f027fc;
}
.btn:hover:after {
  transition-delay: 0s;
  width: 125%;
  left: -15px;
  right: auto;
}
.btn:focus {
  outline: none;
}

.btn-group {
  text-align: center;
  margin: 1em auto;
}
.btn-group .btn {
  margin: 0 15px;
}

.btn--primary {
  color: #02B2FF;
  border-color: #02B2FF;
}
.btn--primary:after {
  background-color: #02B2FF;
}
.btn--primary:hover {
  border-color: #02B2FF;
  color: #fff;
}

.nav--external {
  text-align: right;
}

.nav--footer {
  padding: 1rem 0 5rem;
}

.nav-link {
  font-size: 1.6rem;
  font-weight: 400;
  font-style: italic;
  border: none;
  text-transform: uppercase;
  color: #02B2FF;
  position: relative;
}
.nav-link:after {
  bottom: -2px;
  height: 3px;
  position: absolute;
  left: auto;
  right: 0;
  margin: auto;
  width: 0%;
  background-color: currentcolor;
  content: "";
  transform: skew(-25deg);
  transition: 0.4s 0.1s cubic-bezier(0.19, 1, 0.22, 1), 0.3s 0.1s cubic-bezier(0.19, 1, 0.22, 1) width, 0s cubic-bezier(0.19, 1, 0.22, 1) left, 0s cubic-bezier(0.19, 1, 0.22, 1) right, 0.15s 0s cubic-bezier(0.19, 1, 0.22, 1) background-color;
}
.nav-link:hover {
  color: #02B2FF;
}
.nav-link:hover:after {
  transition-delay: 0s;
  width: 100%;
  left: 0;
  right: auto;
}
.nav-link.is-active {
  color: #fff;
}
.nav--pages .nav-link {
  margin-right: 80px;
}
.nav--pages .nav-link:last-child {
  margin-right: 0;
}
.nav--external .nav-link {
  margin-left: 80px;
  color: #f027fc;
}
.nav--external .nav-link:first-child {
  margin-left: 0;
}
.nav--footer .nav-link {
  margin: 0 25px;
  color: #5C4B61;
}
.nav--footer .nav-link:after {
  bottom: -4px;
}
.nav--footer .nav-link:hover {
  color: #f027fc;
}
.nav--footer .nav-link:hover:after {
  color: #f027fc;
}

.section {
  padding-top: 5em;
  padding-bottom: 5em;
  overflow: hidden;
}

.section--hero {
  background-image: linear-gradient(to top right, #02B2FF, #f027fc 80%);
  min-height: 200px;
  color: #fff;
  position: relative;
  padding: 2.4em 30px;
  max-height: none;
}
.section--hero > .inner {
  margin: 5em auto;
}

.section--yank {
  padding-bottom: 14rem;
  margin-bottom: -10em;
}

.section--alt {
  background-color: #261d29;
}

.section--alt-lighter {
  background-color: #2b212e;
}

.section--blank {
  background-color: transparent;
}

.section-header {
  margin-bottom: 5em;
  padding-bottom: 0em;
}

.section-header--halfgap {
  margin-bottom: 2.5em;
}

.section-header--stroked {
  padding-bottom: 2.5em;
  margin-bottom: 2.5em;
  position: relative;
}
.section-header--stroked:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom: 2px solid #5C4B61;
  display: block;
  margin: auto;
  max-width: 40px;
}

.section-title {
  font-size: 1.6rem;
  font-family: "futura-pt", "Helvetica Neue", Helvetica, Arial;
  font-style: italic;
  color: #9D98A0;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 1em;
  margin-top: 0;
}

.features {
  margin: 5em 0;
  padding: 0;
  list-style-type: none;
}

.feature {
  margin-bottom: 5em;
}

.feature-figure {
  margin-bottom: 1.8em;
}
.feature-figure img {
  display: block;
}

.feature-title {
  font-family: "futura-pt", "Helvetica Neue", Helvetica, Arial;
  font-size: 3rem;
  text-transform: uppercase;
  color: #fff;
  margin: 0 0 0.4em;
  line-height: 1;
}
.feature-title a {
  color: inherit;
}

.feature-summary {
  margin: 0;
  font-size: 2rem;
  color: #5C4B61;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 2rem;
}

.title-hero {
  margin: 0 auto 6rem;
  font-size: 3rem;
  text-align: center;
  max-width: 19em;
}

.title-main {
  color: #02B2FF;
  font-family: "futura-pt", "Helvetica Neue", Helvetica, Arial;
  font-weight: 800;
  font-size: 3rem;
  line-height: 1.3;
  text-transform: uppercase;
  margin: auto;
  max-width: 18em;
}

.title-404 {
  font-size: 2em;
  text-align: center;
  color: #02B2FF;
  font-weight: 400;
}

.works {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.work {
  max-width: 410px;
  width: 31.7829457364%;
  margin: 0 0 4.6em;
}

.work-thumb {
  margin-bottom: 1.4em;
  overflow: hidden;
}
.work-thumb img {
  display: block;
}

.work-details {
  padding: 0 .6em;
}

.work-title,
.work-summary,
.work-when {
  margin: 0;
  font-size: 2rem;
}

.work-title {
  color: #fff;
}
.work-title a {
  color: inherit;
}

.work-summary {
  color: #9D98A0;
}

.project-intro {
  font-weight: 400;
  margin-bottom: 3em;
}

.project-website {
  text-transform: uppercase;
  font-style: italic;
  font-weight: 600;
  font-family: "futura-pt", "Helvetica Neue", Helvetica, Arial;
  border-color: transparent;
}
.project-website:hover {
  border-color: currentcolor;
}

.project-images {
  margin: 5em 5em 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.project-image {
  margin: 0;
}

.project-footer {
  clear: both;
}

.figure {
  margin: 0 0 6em;
}
.figure img {
  display: block;
}

.figure--alt {
  margin-bottom: 3em;
}

.figure--about {
  display: inline-block;
  margin-bottom: -4em;
  padding-bottom: 0;
  width: auto;
}

.figure-caption {
  margin: 0.8em 0;
  font-style: italic;
  font-size: 1.6rem;
  padding: 0 1em;
}
.figure--alt .figure-caption {
  text-align: left;
  padding: 0;
  color: #9D98A0;
  margin-top: 0.2em;
}

.figure-description--above,
.figure-description--below {
  clear: both;
  margin-bottom: 7em;
}

.figure--half {
  max-width: calc(48.8372093023% - 15px);
}

.figure-link {
  position: relative;
}
.figure-link .btn {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  font-size: 2rem;
  border: none;
  transition: 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0;
  transform: translateX(-50%) translateY(-50%) scale3d(1.1, 1.1, 1);
}
.figure-link .btn:before, .figure-link .btn:after {
  content: none;
}
.work .figure-link .btn {
  min-width: 0;
}
.figure-link__link {
  display: block;
  height: 100%;
  width: 100%;
}
.figure-link__clip {
  display: inline-block;
  position: relative;
  overflow: hidden;
  transition: 0.2s cubic-bezier(0.19, 1, 0.22, 1);
}
.figure-link__clip:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0);
  transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0;
}
.figure-link__link:focus .figure-link__clip .btn, .figure-link__link:hover .figure-link__clip .btn {
  opacity: 1;
  transform: translateX(-50%) translateY(-50%) scale3d(1, 1, 1);
}
[data-useragent*="Chrome"] .figure-link__link:focus .figure-link__clip .btn, [data-useragent*="Chrome"] .figure-link__link:hover .figure-link__clip .btn {
  opacity: 0;
}
.feature .figure-link__link:focus .figure-link__clip .btn, .feature .figure-link__link:hover .figure-link__clip .btn {
  font-size: 3rem;
}
.figure-link__link:focus .figure-link__clip:after, .figure-link__link:hover .figure-link__clip:after {
  background: #f901ff;
  opacity: 0.4;
  background-image: linear-gradient(to top right, #02B2FF, #f027fc 80%);
  opacity: 0.8;
}

.taglist--enhance ul,
.taglist {
  position: relative;
  padding: 0 0 0 1.2em;
  margin: 0;
  list-style-type: none;
  text-align: left;
}

.taglist--enhance li:before,
.taglist-item:before {
  content: "–";
  color: #02B2FF;
  position: absolute;
  left: 0;
}

.taglist-title {
  color: #fff;
  margin-bottom: 1em;
  text-align: left;
}

.wrapper {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.header {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #1d161f;
}
.header > .inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}
.header .nav {
  display: flex;
  align-self: center;
}

.logo {
  max-width: 16rem;
  width: 16rem;
  align-self: center;
  transition: 0.8s;
  position: relative;
  z-index: 3;
}
.logo a {
  display: block;
  outline: none;
}
.logo svg {
  fill: white;
  height: 100%;
  width: 100%;
  overflow: visible;
}

.logo-vector {
  transition: 1s cubic-bezier(0.19, 1, 0.22, 1) transform;
  fill: #72EAFF;
  opacity: 1;
}
.js:not([data-useragent*="Firefox"]) .logo-vector {
  transform: scale(0);
  transform-origin: 100% 150%;
}
.js.wf-active .logo-vector {
  transform: scale(1);
  transform-origin: 100% 150%;
}
html:not([data-useragent*="Firefox"]) .logo:hover .logo-vector {
  transition: 0.2s cubic-bezier(0.19, 1, 0.22, 1) transform, 0.2s cubic-bezier(0.19, 1, 0.22, 1) opacity;
  transform: scale(0.9);
  transform-origin: 100% 150%;
}

.logo-text {
  transition: 1.4s cubic-bezier(0.19, 1, 0.22, 1) transform, 0.2s cubic-bezier(0.19, 1, 0.22, 1) opacity;
}
.js .logo-text {
  transform: translateX(-20px);
}
.js.wf-active .logo-text {
  transform: translateX(0);
}

.content strong {
  color: #fff;
}

.footer {
  text-align: center;
  min-height: 10px;
  color: #5C4B61;
  font-size: 1.6rem;
}
.footer:after {
  content: "";
  height: 10px;
  display: block;
  background-image: linear-gradient(to right, #02B2FF, #f027fc 80%);
  background-size: 100% 10px;
}

.footer-inner {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.contain {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: calc(1290px + 60px);
  padding-left: 30px;
  padding-right: 30px;
}

.contain--text {
  max-width: 32em;
}

.contain--narrow {
  max-width: 630px;
}

.contain--features {
  max-width: 1070px;
}

.fourohfour {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background: #000;
}

.fourohfour-footer {
  position: absolute;
  z-index: 4;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1em;
  text-align: center;
}

.no-js #tweet-template {
  display: none;
}

.tweets {
  position: relative;
  margin-bottom: 12rem;
}
.tweets:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 8px;
  height: 20px;
  background-color: white;
  transition: 0.2s;
  opacity: 0;
  animation: none;
}
.tweets.is-busy:before {
  animation: blink 0.25s 0s ease infinite alternate;
  opacity: 1;
}

.tweet {
  width: 31.7829457364%;
  padding: 2.4em;
  text-align: left;
  background-color: #201822;
  transition: 0.4s;
  opacity: 1;
}
.is-busy .tweet {
  opacity: 0;
}

.tweet-link svg {
  transition: 0.2s ease;
  fill: #02B2FF;
}
.tweet-link:hover {
  border-bottom: transparent;
}
.tweet-link:hover svg {
  fill: #f027fc;
}

.tweet-header {
  margin-bottom: 1.2em;
}

.tweet-time {
  font-size: 1.6rem;
  font-style: italic;
  color: #5C4B61;
  font-style: italic;
}

.tweet-body {
  color: #fff;
}

.clients {
  list-style-type: none;
  padding: 0;
}

.client {
  margin: 1em 0;
  width: calc(100%/5);
}

.contact-email {
  border: none;
  color: #02B2FF;
  letter-spacing: 2px;
}

@media screen and (max-width: 1024px) {
  .logo {
    order: 1;
    width: 100%;
    padding-right: 4rem;
  }

  .header {
    flex-direction: column;
    padding-top: 1.4em;
    padding-bottom: 1.4em;
    height: auto;
    max-height: none;
    background-position: 30% -50%;
  }
  .header .nav--pages {
    order: 2;
    justify-content: space-around;
    max-width: 24em;
    width: 100%;
  }
  .header .nav--external {
    display: none;
  }

  .nav--pages .nav-link {
    margin: 0.4em 0;
  }

  .title-hero {
    font-size: 2.4rem;
    max-width: 20em;
  }
  .title-hero br {
    display: none;
  }
}
@media screen and (max-width: 900px) {
  .client {
    width: calc(100%/3);
  }

  .section {
    padding-top: 4em;
    padding-bottom: 4em;
  }

  .section--hero .inner,
  .features {
    margin-top: 4em;
    margin-bottom: 4em;
  }

  .figure {
    margin-bottom: 4em;
  }

  .section-header {
    margin-bottom: 2.8em;
  }

  .section--yank {
    margin-bottom: -7em;
  }

  .tweet {
    width: 100%;
    margin-bottom: 30px;
  }

  .work {
    width: 48.8372093023%;
  }

  .figure--about {
    margin-bottom: -4em;
    padding-bottom: 0;
  }
}
@media screen and (max-width: 800px) {
  .nav--footer {
    column-count: 2;
  }
  .nav--footer .nav-link {
    width: 50%;
    display: inline-block;
    margin: 1em;
  }

  .project-images .figure {
    max-width: none;
    width: 100%;
  }
}
@media screen and (max-width: 680px) {
  .works {
    justify-content: space-around;
  }

  .work {
    width: 100%;
  }

  .skills {
    padding: 0 1em;
  }

  .skill {
    width: 100%;
  }

  .taglist {
    padding-left: 1em;
  }

  .btn-group {
    width: 100%;
  }

  .btn {
    display: block;
  }
  .btn-group .btn {
    margin: 0 0 30px;
  }

  .section p {
    font-size: 1.8rem;
  }

  .header .nav--pages {
    justify-content: space-around;
  }
}
@media screen and (max-width: 480px) {
  .contact-email {
    font-size: 1.8rem;
  }

  .tweet {
    padding: 30px;
  }

  .tweet-body {
    font-size: 1.8rem;
  }
}
/**
 * Retina.
 * Currently only set to 'double' retina, meaning, assets only have to be twice the normal size,
 * @reference http://www.brettjankord.com/2012/11/28/cross-browser-retinahigh-resolution-media-queries/
 */
@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}


