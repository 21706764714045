.icon {
    width: 32px;
    height: 32px;
    margin: auto;
    fill: white;
    display: inline-block;
}

.icon--light {
    fill: $c-echo;
}

.icon--small {
    width: 24px;
    height: 24px;
    margin-right: 0.2em;
    position: relative;
    top: 0.3em;
}

.icon--block {
    display: block;
    margin-bottom: $d-gutter;
}

.polygon {
    width: 32px;
    align-self: center;
    svg {
        stroke: #00ffff;
        stroke-width: 16px;
        fill: none;
    }
}
