@media screen and (max-width: 1024px) {

    .logo {
        order: 1;
        width: 100%;
        padding-right: 4rem;
        // max-width: 14rem;
    }
    .header {
        flex-direction: column;
        padding-top: 1.4em;
        padding-bottom: 1.4em;
        height: auto;
        max-height: none;
        background-position: 30% -50%;

        .nav--pages {
            order: 2;
            justify-content: space-around;
            max-width: 24em;
            width: 100%;
        }
        .nav--external {
            display: none;
        }

    }
    .nav--pages {
        .nav-link {
            margin: 0.4em 0;
        }
    }

    .title-hero {
        font-size: 2.4rem;
        max-width: 20em;
        br {
            display: none;
        }
    }

}

@media screen and (max-width: 900px) {

    .client {
        width: calc(100%/3);
    }

    $x: 4em;
    .section {
        padding-top: $x;
        padding-bottom: $x;
    }
    .section--hero .inner,
    .features {
        margin-top: $x;
        margin-bottom: $x;
    }
    .figure {
        margin-bottom: $x;
    }

    .section-header {
        margin-bottom: 2.8em;
    }

    .section--yank {
            margin-bottom: -7em;
    }

    .tweet {
        width: 100%;
        margin-bottom: $d-gutter;
    }

    .work {
        $width: (($d-column*6) + ($d-gutter*5)) / $d-siteWidth * 100%;
        width: $width;
    }

    .figure--about {
        margin-bottom: -4em;
        padding-bottom: 0;
    }

}

@media screen and (max-width: 800px) {
    .nav--footer {
        column-count: 2;
        .nav-link {
            width: 50%;
            display: inline-block;
            margin: 1em;
        }
    }
    .project-images .figure {
        max-width: none;
        width: 100%;
    }
}

@media screen and (max-width: 680px) {
    .works {
        justify-content: space-around;
    }
    .work {
        width: 100%;
    }
    .skills {
        padding: 0 1em;
    }
    .skill {
        width: 100%;
    }
    .taglist {
        padding-left: 1em;
    }
    .btn-group {
        width: 100%;
        // padding: 0 $d-gutter;

        // .section--hero & {
        //     padding: 0 0;
        // }
    }
    .btn {
        display: block;
        .btn-group & {
            margin: 0 0 $d-gutter;

        }
    }
    .section p {
        font-size: 1.8rem;
    }

    .header .nav--pages {
        justify-content: space-around;
    }
}

@media screen and (max-width: 480px) {
    .contact-email {
        font-size: 1.8rem;
    }
    .tweet {
        padding: $d-gutter;
    }
    .tweet-body {
        font-size: 1.8rem;
    }
}


/**
 * Retina.
 * Currently only set to 'double' retina, meaning, assets only have to be twice the normal size,
 * @reference http://www.brettjankord.com/2012/11/28/cross-browser-retinahigh-resolution-media-queries/
 */
@media  (-webkit-min-device-pixel-ratio: 2),
        (min--moz-min-device-pixel-ratio: 2),
        (min-device-pixel-ratio: 2),
        (min-resolution: 192dpi),
        (min-resolution: 2dppx) {

}
