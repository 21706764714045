
.taglist--enhance ul,
.taglist {
    position: relative;
    padding: 0 0 0 1.2em;
    margin: 0;
    list-style-type: none;
    text-align: left;
}
.taglist--enhance li,
.taglist-item {
    &:before {
        content: "–";
        color: $c-alpha;
        position: absolute;
        left: 0;
    }
}
.taglist-title {
    color: $c-white;
    margin-bottom: 1em;
    text-align: left;
}
