.works {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}
.work {
    max-width: 410px;
    width: $d-columns-4;
    margin: 0 0 4.6em;
}
.work-thumb {
    margin-bottom: 1.4em;
    overflow: hidden;

    img {
        display: block;
    }

}
.work-details {
    padding: 0 .6em;
}
.work-title,
.work-summary,
.work-when {
    margin: 0;
    font-size: 2rem;
}
.work-title {
    color: $c-white;
    a {
        color: inherit;
    }
}
.work-summary {
    color: $c-foxtrot;
}
