.wrapper {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.header {
    padding-top: $d-gutter;
    padding-bottom: $d-gutter;
    background-color: $c-bg;

    > .inner {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
    }

    // .page--home & {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     right: 0;
    //     width: 100%;
    //     z-index: 1;

    //     padding-top: $d-gutter;
    //     padding-bottom: $d-gutter;

    // }

    .nav {
        display: flex;
        align-self: center;
    }
}

.logo {
    max-width: 16rem;
    width: 16rem;
    align-self: center;
    transition: 0.8s;
    position: relative;
    z-index: 3;

    a { display: block; outline: none; }

    svg {
        fill: white;
        height: 100%;
        width: 100%;
        overflow: visible;
    }

}

.logo-vector {
    transition: 1s $easeOutExpo transform;
    fill: #72EAFF;
    opacity: 1;

    .js:not([data-useragent*="Firefox"]) & {
        transform: scale(0);
        transform-origin: 100% 150%;
    }
    .js.wf-active & {
        transform: scale(1);
        transform-origin: 100% 150%;
    }

    html:not([data-useragent*="Firefox"]) .logo:hover & {
        transition:
            0.2s $easeOutExpo transform,
            0.2s $easeOutExpo opacity;
        transform: scale(0.9);
        transform-origin: 100% 150%;
    }
}

.logo-text {
    transition:
        1.4s $easeOutExpo transform,
        0.2s $easeOutExpo opacity;

    .js & {
        transform: translateX(-20px);
    }
    .js.wf-active & {
        transform: translateX(0);
    }
}

.content {
    strong {
        color: $c-white;
    }
}

.footer {
    text-align: center;
    min-height: 10px;
    color: $c-echo;
    font-size: 1.6rem;

    &:after {
        content: "";
        height: 10px;
        display: block;
        background-image: $c-gradientFooter;
        background-size: 100% 10px;
    }
}

.footer-inner {
    padding-top: 10rem;
    padding-bottom: 10rem;
}

.contain {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: calc(#{$d-siteWidth} + #{$d-gutter*2});
    padding-left: $d-gutter;
    padding-right: $d-gutter;
}

.contain--text {
    max-width: 32em;
}
.contain--narrow {
    max-width: ($d-gutter*5) + ($d-column*6);
}

.contain--features {
    max-width: ($d-gutter*9) + ($d-column*10);
}

.fourohfour {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background: #000;
}

.fourohfour-footer {
    position: absolute;
    z-index: 4;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1em;
    text-align: center;
}
