* { box-sizing: border-box; }

::-moz-selection {
    background-color: $c-bravo;
    color: $c-white;
}
::selection {
    background-color: $c-bravo;
    color: $c-white;
    animation: 4s 0s test linear alternate infinite;
}
a::-moz-selection {
    background-color: $c-alpha;
    color: $c-white;
}
a::selection {
    background-color: $c-alpha;
    color: $c-white;
}

html, body {
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
}
html {
    font-size: 62.5%;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
}
body {
    font-family: $ff;
    background-color: $c-bg;
    color: $c-text;
    font-size: 2rem;
    font-weight: $fw;
    line-height: 1.6;
    text-align: center;
    display: flex;
    min-height: 100vh;
    flex-direction: column;

    .js.no-font & {
        transition: 0.4s ease-out opacity;
        opacity: 0;
    }
    .js.wf-inactive &,
    .js.wf-active & {
        opacity: 1;
    }

    &.is-konami {
        animation: 6s linear 1 miamivice-flash 0s forwards;
    }
}

img {
    max-width: 100%;
}

p:last-child {
    margin-bottom: 0;
}

a {
    color: $c-alpha;
    text-decoration: none;
    transition:
        0.3s ease border-color,
        0.3s ease color;
    border-bottom: 2px solid transparent;

    figure & {
        border: none;
    }

    .status & {
        color: $c-white;
        border-bottom: 2px solid currentColor;
    }
}
a:hover {
    color: $c-bravo;
    border-bottom-color: currentColor;

    .status & {
        color: $c-white;
        border-bottom-color: transparent;
    }
}
a:active {}
a:focus {}