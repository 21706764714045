/**
 * Clearfix
 */
%clear {
    content: ' ';
    display: table;
}
.u-cf {
    *zoom: 1;
    &:before {
        @extend %clear;
    }
    &:after {
        @extend %clear;
        line-height: 0;
        clear: both;
    }
}

/**
 * Responsive Images
 */
%ri {
    > img { max-width: 100%; }
}
.u-ri {
    @extend %ri;
    } // responsive image

/**
 * Image Replacement - HTML5BP
 */
%ir {
    border: 0;
    font: 0/0 a;
    text-shadow: none;
    color: transparent;
}
.u-ir {
    @extend %ir;
}

.u-textleft {
    text-align: left;
}


// Flexy-time
.u-flex {
    display: flex;
    flex-wrap: wrap;
}
.u-flex--row {
    justify-content: space-around;
    align-items: flex-start;
}
.u-flex--spacebetween {
    justify-content: space-between;
}
.u-flex--stretch {
    align-items: stretch;
}
.u-flex--center-v {
    align-items: center;
}

// Responsive Video
.u-rv {
    position: absolute;
    height: 0%;
    width: 100%;
    padding-bottom: 56.25%;
    top: 0;
    bottom: 0;
    margin: auto;

    iframe,
    object,
    video {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    }
}

.u-grain {
    &:before {
        content: "";
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADAgMAAAArRl0sAAAADFBMVEUAAAD///////////84wDuoAAAABHRSTlMADRoztLZuBAAAAA5JREFUCB1jOMCgwMACAAQqAOWPj3ieAAAAAElFTkSuQmCC");
        background-repeat: repeat;
        background-position: center center;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 2;
    }
}
