.figure {
    margin: 0 0 6em;
    img { display: block; }
}
.figure--alt {
    margin-bottom: 3em;
}

.figure--about {
    display: inline-block;
    margin-bottom: -4em;
    padding-bottom: 0;
    width: auto;
}

.figure-caption {
    margin: 0.8em 0;
    font-style: italic;
    font-size: 1.6rem;
    padding: 0 1em;

    .figure--alt & {
        text-align: left;
        padding: 0;
        color: $c-foxtrot;
        margin-top: 0.2em;
    }
}
.figure-description {}

.figure-description--above,
.figure-description--below {
    clear: both;
    margin-bottom: 7em;
}

.figure--half {
    max-width: calc(#{$d-columns-6} - #{($d-gutter / 2)});
}

.figure-link {
    position: relative;

    .btn {
        cursor: pointer;
        position: absolute;
        top: 50%;
        left: 50%;
        color: $c-white;
        font-size: 2rem;
        border: none;
        transition: 0.6s $easeOutExpo;
        opacity: 0;
        transform: translateX(-50%) translateY(-50%) scale3d(1.1, 1.1, 1);

        &:before,
        &:after {
            content: none;
        }

        .work & {
            min-width: 0;
        }

    }

    &__link {
        display: block;
        height: 100%;
        width: 100%;
    }

    &__clip {
        display: inline-block;
        position: relative;
        overflow: hidden;

        transition: 0.2s $easeOutExpo;

        [data-useragent*="Chrome"] & {
            // mask-image: url('../img/bl-logo.svg');
            // mask-repeat: no-repeat;
            // mask-position:  50% 50%;
            // mask-size: -1%;

            // -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
            //         clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            background-color: rgba(0, 0, 0, 0);
            transition: 0.4s $easeOutExpo;
            opacity: 0;
        }

        .figure-link__link:focus &,
        .figure-link__link:hover & {

            [data-useragent*="Chrome"] & {
                // clip-path: url('../img/bl-logo.svg');
                // mask-image: url('../img/bl-logo.svg');
                // mask-size: 70%;

                // -webkit-clip-path: polygon(42% 10%, 90% 60%, 18% 85%, 18% 85%);
                //     clip-path: polygon(42% 10%, 90% 60%, 18% 85%, 18% 85%);
            }

            .feature & {
                [data-useragent*="Chrome"] & {
                    // -webkit-clip-path: polygon(43% 18%, 76% 56%, 30% 80%, 30% 80%);
                    //         clip-path: polygon(43% 18%, 76% 56%, 30% 80%, 30% 80%);
                }
            }

            .btn {
                opacity: 1;
                transform: translateX(-50%) translateY(-50%) scale3d(1, 1, 1);

                [data-useragent*="Chrome"] & {
                    opacity: 0;
                }

                .feature & {
                    font-size: 3rem;
                }
            }
            &:after {
                background: #f901ff;
                opacity: 0.4;

                // html:not([data-useragent*="Chrome"]) & {
                    background-image: $c-gradientHero;
                    opacity: 0.8;
                // }
            }
        }
    }
}
